<template>
  <div>
    <router-view/>
    <div class="notification-panel" v-if="notificationPanel">
      <ExclamationIcon class="icon" />
      <div class="notification-text">
        {{ notificationMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ExclamationIcon } from '@heroicons/vue/solid';

export default {
  computed: {
    ...mapState([
      'notificationPanel',
      'notificationMessage',
    ]),
  },
  components: {
    ExclamationIcon,
  },
  methods: {
    ...mapActions([
      'getLoggedUser',
    ]),
  },
  async mounted() {
    await this.getLoggedUser();
    console.log('Initial point');
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
  font-size: 15px;

  min-height: 100vh;
  background-color: #f9fafb;
}
</style>

<style lang="scss" scoped>
.notification-panel {
  position: fixed;
  right: 16px;
  top: 16px;
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  padding: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  max-width: 380px;
  border: 1px solid rgb(184, 184, 184);
  animation-name: blinking;
  animation-duration: 2s;
  animation-iteration-count: 100;
}
.icon {
  color: red;
  width: 40px;
  margin-right: 20px;
}
.notification-text {
  line-height: 1.2;
}

@keyframes blinking {
  50% {
    border-color: rgb(255, 103, 103);;
  }
}
</style>
