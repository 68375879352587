import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Home,
  },
  {
    path: '/home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Drive.vue'),
  },
  {
    path: '/admin',
    component: () => import('../views/Admin.vue'),
  },
  {
    path: '/users',
    component: () => import('../views/Users.vue'),
  },
  {
    path: '/form-submissions',
    component: () => import('../views/FormSubmissions.vue'),
  },
  {
    path: '/upload',
    component: () => import('../views/Upload.vue'),
  },
  {
    path: '/calendar',
    component: () => import('../views/Calendar.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
