import axios from 'axios';
import { createStore } from 'vuex';
// import router from '../router';

const baseUrl = process.env.VUE_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status && error.response.status === 401) {
    // router.push('/');
  }
  return Promise.reject(error);
});

export default createStore({
  state: {
    notificationPanel: false,
    notificationMessage: '',
    userType: null,
    files: [],
    folders: null,
    events: [],
    users: [],
    formSubmissions: [],
  },
  mutations: {
    showNotificationPanel(state) {
      state.notificationPanel = true;
    },
    hideNotificationPanel(state) {
      state.notificationPanel = false;
    },
    setNotificationPanel(state, message) {
      state.notificationMessage = message;
    },
    setUserType(state, type) {
      state.userType = type;
    },
    setFiles(state, files) {
      state.files = files;
    },
    setFolders(state, folders) {
      state.folders = folders;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setFormSubmissions(state, formSubmissions) {
      state.formSubmissions = formSubmissions;
    },
  },
  actions: {
    async login({ commit }, payload) {
      const { data } = await axiosInstance.post('/login', {
        email: payload.email,
        password: payload.password,
      });
      commit('setUserType', data.type);
    },
    async getLoggedUser({ commit }) {
      try {
        const { data } = await axiosInstance.get('/auth');
        commit('setUserType', data.type);
      } catch (error) {
        commit('setUserType', null);
      }
    },
    async logout({ commit }) {
      await axiosInstance.get('/logout');
      commit('setUserType', null);
    },
    async showNotification({ commit }, message) {
      commit('showNotificationPanel');
      commit('setNotificationPanel', message);

      setTimeout(() => {
        commit('hideNotificationPanel');
      }, 5000);
    },
    async uploadNewFile({ dispatch }, payload) {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axiosInstance.post('/file', payload, { headers });
      await dispatch('getAllFiles');
    },
    async removeFile({ dispatch }, payload) {
      await axiosInstance.delete(`/file/${payload}`);
      await dispatch('getAllFiles');
    },
    async updateFileName({ dispatch }, payload) {
      // await axiosInstance.post(`/file/${payload.id}/${payload.name}`);
      await axiosInstance.patch(`/file/${payload.id}`, {
        name: payload.name,
      });
      await dispatch('getAllFiles');
    },
    async downloadFile(context, payload) {
      try {
        const { data } = await axiosInstance.post('/get-file', {
          filename: payload,
        });
        return data.url;
      } catch (error) {
        return null;
      }
    },
    async getAllFiles({ commit }) {
      const { data } = await axiosInstance.get('/files');
      commit('setFiles', data);
    },
    async getFolders({ commit }) {
      const { data } = await axiosInstance.get('/folders');
      commit('setFolders', data);
    },
    async createNewFolder({ dispatch }, payload) {
      await axiosInstance.post('/folder', {
        folderName: payload,
      });
      await dispatch('getAllFiles');
    },
    async removeFolder({ dispatch }, payload) {
      await axiosInstance.delete(`/folder/${payload}`);
      await dispatch('getAllFiles');
    },
    async updateFolderName({ dispatch }, payload) {
      // await axiosInstance.post(`/folder/${payload.id}/${payload.name}`);
      await axiosInstance.patch(`/folder/${payload.id}`, {
        name: payload.name,
      });
      await dispatch('getAllFiles');
    },
    async getCalendarEvents({ commit }) {
      const { data } = await axiosInstance.get('/calendar');
      commit('setEvents', data);
    },
    async getAllUsers({ commit }) {
      const { data } = await axiosInstance.get('/users');
      commit('setUsers', data);
    },
    async createNewUser({ dispatch }, payload) {
      await axiosInstance.post('/user', {
        email: payload.email,
        password: payload.password,
      });
      await dispatch('getAllUsers');
    },
    async removeUser({ dispatch }, payload) {
      await axiosInstance.delete(`/user/${payload}`);
      await dispatch('getAllUsers');
    },
    async submitFormInfo(state, data) {
      await axiosInstance.post('/form-submission', data);
      // const headers = { 'Content-Type': 'multipart/form-data' };
      // await axiosInstance.post('/form-submission', data, { headers });
    },
    async getFormSubmissions({ commit }) {
      const { data } = await axiosInstance.get('/get-form-submissions');
      commit('setFormSubmissions', data);
    },
  },
});
