<template>
  <div class="login-container">
    <div class="login">
      <div class="logo">
        <img src="/VilexLogoRed.svg" alt="">
      </div>
      <h1 class="h1">
        Sign in to your account
      </h1>
      <div class="login-card">
        <form class="login-form" @submit.prevent="submitAction">
          <label class="label">
            <div class="label-text">
              Email
            </div>
            <input type="text" class="input" v-model="email">
          </label>
          <label for="" class="label">
            <div class="label-text">
              Password
            </div>
            <input type="password" class="input" v-model="password">
          </label>
          <div class="forgot-password">
            Forgot your password?
          </div>
          <input type="submit" class="submit" value="Sign in">
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapState([
      'userType',
    ]),
  },
  methods: {
    async submitAction() {
      try {
        await this.login({
          email: this.email,
          password: this.password,
        });

        if (this.userType === 'admin') {
          this.$router.push({ path: 'admin' });
        } else {
          this.$router.push({ path: 'home' });
        }
      } catch (error) {
        this.showNotification('You have entered an invalid username or password');
        console.log(error);
      }
    },
    ...mapActions([
      'showNotification',
      'login',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.h1 {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}
.logo {
  width: 140px;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: auto;;
  }
}
.login-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-card {
  width: 500px;
  max-width: 95%;
  padding: 34px 38px;
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  margin-top: 30px;
  border-radius: 8px;
}
.label {
  width: 100%;
  margin-bottom: 24px;
}
.label-text {
  width: 100%;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 8px;
  color: rgb(92, 92, 92);
}
.input {
  border: 1px solid rgb(216, 216, 216);
  padding: 10px 12px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 2%), 0 1px 2px rgb(0 0 0 / 2%);
  width: 100%;
  font-size: inherit;
}
.forgot-password {
  color: #bc1334;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}
.submit {
  background-color: #b71435;
  border: none;
  color: white;
  font-family: inherit;
  width: 100%;
  padding: 12px 0;
  font-size: 15px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 2%), 0 1px 2px rgb(0 0 0 / 2%);
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }
}
</style>
